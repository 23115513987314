import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import { Box } from "@chakra-ui/react";
import TaxBracket from "./TaxBracket";
export default function TaxableIncome({
  monthlyGrossTotal,
  totalDeduction,
  selectedDeductions,
  salaryDetails,
  selectedMonth,
  reportType,
}) {
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  useEffect(() => {
    if (selectedDeductions) {
      setValue1(selectedDeductions[0]?.amount ?? 0);
      setValue2(selectedDeductions[1]?.amount ?? 0);
    }
  }, [selectedDeductions]);
  const taxableIncome =
    monthlyGrossTotal * 12 - value1 * 12 - value2 * 12 - totalDeduction * 12;

  const taxableIncomeMonthly =
    monthlyGrossTotal - value1 - value2 - totalDeduction;
  return (
    <Box mt="2rem">
      <Table striped bordered hover id="box-width">
        <thead>
          <tr>
            <th style={{ background: "green", color: "#fff" }}>
              Total {reportType === "Monthly"?"Monthly":"Annual"} Taxable Income
            </th>
            <th style={{ background: "green", color: "#fff" }}>
              {reportType === "Monthly" ? taxableIncomeMonthly : taxableIncome}
            </th>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
            <td>Bonus</td>
            <td>{salaryDetails?.bonus}</td>
          </tr> */}
          <tr>
            <td>Total Annual Taxable Income</td>
            {reportType === "Monthly" ? (
              <td>{taxableIncomeMonthly}</td>
            ) : (
              <td>{taxableIncome}</td>
            )}
          </tr>
        </tbody>
      </Table>
      <TaxBracket
        selectedMonth={selectedMonth}
        reportType={reportType}
        totalDeduction={totalDeduction}
        calculatedTaxableIncome={taxableIncome}
        monthlyGrossTotal={monthlyGrossTotal}
        salaryDetails={salaryDetails}
      />
    </Box>
  );
}
