export const MONTH = [
  { label: "Baishak", year: "2080", start: "2023-4-14", end: "2023-5-14" },
  { label: "Jestha", year: "2080", start: "2023-5-15", end: "2023-6-15" },
  { label: "Ashadh", year: "2080", start: "2023-6-16", end: "2023-7-16" },
  { label: "Shrawan", year: "2080", start: "2023-7-17", end: "2023-8-17" },
  { label: "Bhadra", year: "2080", start: "2023-8-18", end: "2023-9-17" },
  { label: "Ashoj", year: "2080", start: "2023-9-18", end: "2023-10-17" },
  { label: "Kartik", year: "2080", start: "2023-10-18", end: "2023-11-17" },
  { label: "Mangsir", year: "2080", start: "2023-11-17", end: "2023-12-16" },
  { label: "Poush", year: "2080", start: "2023-12-17", end: "2024-1-14" },
  { label: "Magh", year: "2080", start: "2024-1-15", end: "2024-2-12" },
  { label: "Falgun", year: "2080", start: "2024-2-13", end: "2024-3-13" },
  { label: "Chaitra", year: "2080", start: "2024-3-14", end: "2024-4-12" },

  { label: "Baishak", year: "2081", start: "2024-4-13", end: "2024-5-13" },
  { label: "Jestha", year: "2081", start: "2024-5-14", end: "2024-6-14" },
  { label: "Ashadh", year: "2081", start: "2024-6-15", end: "2024-7-15" },
  { label: "Shrawan", year: "2081", start: "2024-7-16", end: "2024-8-16" },
  { label: "Bhadra", year: "2081", start: "2024-8-17", end: "2024-9-16" },
  { label: "Ashoj", year: "2081", start: "2024-9-17", end: "2024-10-16" },
  { label: "Kartik", year: "2081", start: "2024-10-17", end: "2024-11-15" },
  { label: "Mangsir", year: "2081", start: "2024-11-16", end: "2024-12-15" },
  { label: "Poush", year: "2081", start: "2024-12-16", end: "2025-1-13" },
  { label: "Magh", year: "2081", start: "2025-1-14", end: "2025-2-12" },
  { label: "Falgun", year: "2081", start: "2025-2-13", end: "2025-3-14" },
  { label: "Chaitra", year: "2081", start: "2025-3-15", end: "2025-4-13" },

  { label: "Baishak", year: "2082", start: "4-14-2025", end: "5-13-2025" },
  { label: "Jestha", year: "2082", start: "5-14-2025", end: "6-14-2025" },
  { label: "Ashadh", year: "2082", start: "6-15-2025", end: "7-15-2025" },
  { label: "Shrawan", year: "2082", start: "7-16-2025", end: "8-16-2025" },
  { label: "Bhadra", year: "2082", start: "8-17-2025", end: "9-16-2025" },
  { label: "Ashoj", year: "2082", start: "9-17-2025", end: "10-16-2025" },
  { label: "Kartik", year: "2082", start: "10-17-2025", end: "11-15-2025" },
  { label: "Mangsir", year: "2082", start: "11-16-2025", end: "12-15-2025" },
  { label: "Poush", year: "2082", start: "12-16-2025", end: "1-13-2026" },
  { label: "Magh", year: "2082", start: "1-14-2026", end: "2-12-2026" },
  { label: "Falgun", year: "2082", start: "2-13-2026", end: "3-14-2026" },
  { label: "Chaitra", year: "2082", start: "3-15-2026", end: "4-13-2026" },

  { label: "Baishak", year: "2083", start: "4-14-2026", end: "5-14-2026" },
  { label: "Jestha", year: "2083", start: "5-15-2026", end: "6-14-2026" },
  { label: "Ashadh", year: "2083", start: "6-15-2026", end: "7-16-2026" },
  { label: "Shrawan", year: "2083", start: "7-17-2026", end: "8-16-2026" },
  { label: "Bhadra", year: "2083", start: "8-17-2026", end: "9-16-2026" },
  { label: "Ashoj", year: "2083", start: "9-17-2026", end: "10-16-2026" },
  { label: "Kartik", year: "2083", start: "10-17-2026", end: "11-15-2026" },
  { label: "Mangsir", year: "2083", start: "11-16-2026", end: "12-15-2026" },
  { label: "Poush", year: "2083", start: "12-16-2026", end: "1-13-2027" },
  { label: "Magh", year: "2083", start: "1-14-2027", end: "2-12-2027" },
  { label: "Falgun", year: "2083", start: "2-13-2027", end: "3-14-2027" },
  { label: "Chaitra", year: "2083", start: "3-15-2027", end: "4-13-2027" },

  { label: "Baishak", year: "2084", start: "4-14-2027", end: "5-14-2027" },
  { label: "Jestha", year: "2084", start: "5-15-2027", end: "6-14-2027" },
  { label: "Ashadh", year: "2084", start: "6-15-2027", end: "7-16-2027" },
  { label: "Shrawan", year: "2084", start: "7-17-2027", end: "8-16-2027" },
  { label: "Bhadra", year: "2084", start: "8-17-2027", end: "9-16-2027" },
  { label: "Ashoj", year: "2084", start: "9-17-2027", end: "10-16-2027" },
  { label: "Kartik", year: "2084", start: "10-17-2027", end: "11-15-2027" },
  { label: "Mangsir", year: "2084", start: "11-16-2027", end: "12-15-2027" },
  { label: "Poush", year: "2084", start: "12-16-2027", end: "1-13-2027" },
  { label: "Magh", year: "2084", start: "1-14-2028", end: "2-12-2028" },
  { label: "Falgun", year: "2084", start: "2-13-2028", end: "3-13-2028" },
  { label: "Chaitra", year: "2084", start: "3-14-2028", end: "4-12-2028" },

  { label: "Baishak", year: "2085", start: "4-13-2028", end: "5-13-2028" },
  { label: "Jestha", year: "2085", start: "5-14-2028", end: "6-14-2028" },
  { label: "Ashadh", year: "2085", start: "6-15-2028", end: "7-15-2028" },
  { label: "Shrawan", year: "2085", start: "7-16-2028", end: "8-16-2028" },
  { label: "Bhadra", year: "2085", start: "8-17-2028", end: "9-15-2028" },
  { label: "Ashoj", year: "2085", start: "9-16-2028", end: "10-16-2028" },
  { label: "Kartik", year: "2085", start: "10-17-2028", end: "11-15-2028" },
  { label: "Mangsir", year: "2085", start: "11-16-2028", end: "12-15-2028" },
  { label: "Poush", year: "2085", start: "12-16-2028", end: "1-13-2029" },
  { label: "Magh", year: "2085", start: "1-14-2029", end: "2-12-2029" },
  { label: "Falgun", year: "2085", start: "2-13-2029", end: "3-14-2029" },
  { label: "Chaitra", year: "2085", start: "3-15-2029", end: "4-13-2029" },
];

export const constantMonth = [
  "Baishak",
  "Jestha",
  "Ashadh",
  "Shrawan",
  "Bhadra",
  "Ashoj",
  "Kartik",
  "Mangsir",
  "Poush",
  "Magh",
  "Falgun",
  "Chaitra",
];
export const constantYear = ["2080", "2081", "2082", "2083", "2084", "2085"];

//english month and year

export const filterMonth = [
  { label: "January", year: "2023", start: "2023-1" },
  { label: "February", year: "2023", start: "2023-2" },
  { label: "March", year: "2023", start: "2023-3" },
  { label: "April", year: "2023", start: "2023-4" },
  { label: "May", year: "2023", start: "2023-5" },
  { label: "June", year: "2023", start: "2023-6" },
  { label: "July", year: "2023", start: "2023-7" },
  { label: "August", year: "2023", start: "2023-8" },
  { label: "September", year: "2023", start: "2023-9" },
  { label: "October", year: "2023", start: "2023-10" },
  { label: "November", year: "2023", start: "2023-11" },
  { label: "December", year: "2023", start: "2023-12" },
  { label: "January", year: "2024", start: "2024-1" },
  { label: "February", year: "2024", start: "2024-2" },
  { label: "March", year: "2024", start: "2024-3" },
  { label: "April", year: "2024", start: "2024-4" },
  { label: "May", year: "2024", start: "2024-5" },
  { label: "June", year: "2024", start: "2024-6" },
  { label: "July", year: "2024", start: "2024-7" },
  { label: "August", year: "2024", start: "2024-8" },
  { label: "September", year: "2024", start: "2024-9" },
  { label: "October", year: "2024", start: "2024-10" },
  { label: "November", year: "2024", start: "2024-11" },
  { label: "December", year: "2024", start: "2024-12" },
  { label: "January", year: "2025", start: "2025-1" },
  { label: "February", year: "2025", start: "2025-2" },
  { label: "March", year: "2025", start: "2025-3" },
  { label: "April", year: "2025", start: "2025-4" },
  { label: "May", year: "2025", start: "2025-5" },
  { label: "June", year: "2025", start: "2025-6" },
  { label: "July", year: "2025", start: "2025-7" },
  { label: "August", year: "2025", start: "2025-8" },
  { label: "September", year: "2025", start: "2025-9" },
  { label: "October", year: "2025", start: "2025-10" },
  { label: "November", year: "2025", start: "2025-11" },
  { label: "December", year: "2025", start: "2025-12" },
  { label: "January", year: "2026", start: "2026-1" },
  { label: "February", year: "2026", start: "2026-2" },
  { label: "March", year: "2026", start: "2026-3" },
  { label: "April", year: "2026", start: "2026-4" },
  { label: "May", year: "2026", start: "2026-5" },
  { label: "June", year: "2026", start: "2026-6" },
  { label: "July", year: "2026", start: "2026-7" },
  { label: "August", year: "2026", start: "2026-8" },
  { label: "September", year: "2026", start: "2026-9" },
  { label: "October", year: "2026", start: "2026-10" },
  { label: "November", year: "2026", start: "2026-11" },
  { label: "December", year: "2026", start: "2026-12" },
  { label: "January", year: "2027", start: "2027-1" },
  { label: "February", year: "2027", start: "2027-2" },
  { label: "March", year: "2027", start: "2027-3" },
  { label: "April", year: "2027", start: "2027-4" },
  { label: "May", year: "2027", start: "2027-5" },
  { label: "June", year: "2027", start: "2027-6" },
  { label: "July", year: "2027", start: "2027-7" },
  { label: "August", year: "2027", start: "2027-8" },
  { label: "September", year: "2027", start: "2027-9" },
  { label: "October", year: "2027", start: "2027-10" },
  { label: "November", year: "2027", start: "2027-11" },
  { label: "December", year: "2027", start: "2027-12" },
];

export const englishMonth = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const constantEnglishYear = ["2023", "2024", "2025", "2026", "2027"];
