import React from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import { Box } from "@chakra-ui/react";
export default function MOnthlyDeduction({
  salaryDetails,
  totalDeduction,
  selectedMonth,
  reportType,
}) {
  return (
    <Box mt="2rem">
      {reportType === "Monthly" ? (
        <Table striped bordered hover id="box-width">
          <thead>
            <tr>
              <th style={{ background: "orange", color: "#fff" }}>
                Monthly Deduction
              </th>
              <th style={{ background: "orange", color: "#fff" }}></th>
            </tr>
          </thead>
          <tbody>
            {salaryDetails?.deduction?.map((item, index) => {
              return (
                <tr>
                  <td>{item?.allowance_deduction_name}</td>
                  <td>{item?.amount}</td>
                </tr>
              );
            })}

            <tr>
              <td>Total Deduction Monthly</td>
              <td>{totalDeduction}</td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <Table striped bordered hover id="box-width">
          <thead>
            <tr>
              <th style={{ background: "orange", color: "#fff" }}>
                Monthly Deduction
              </th>
              <th style={{ background: "orange", color: "#fff" }}></th>
              <th style={{ background: "orange", color: "#fff" }}></th>
            </tr>
          </thead>
          <tbody>
            {salaryDetails?.deduction?.map((item, index) => {
              return (
                <tr>
                  <td>{item?.allowance_deduction_name}</td>
                  <td>{item?.amount}</td>
                  <td>{item?.amount * 12}</td>
                </tr>
              );
            })}

            <tr>
              <td>Total Deduction Monthly</td>
              <td>{totalDeduction}</td>
              <td>{totalDeduction * 12}</td>
            </tr>
          </tbody>
        </Table>
      )}
    </Box>
  );
}
