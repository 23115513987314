import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Box,
  Button,
  Select,
  Radio,
  RadioGroup,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { refreshToken, baseUrl } from "../constants/constant";
import axios from "axios";
import {
  MONTH,
  constantEnglishYear,
  constantMonth,
  constantYear,
  englishMonth,
  filterMonth,
} from "../constants/dropdown";

import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import DetailsTable from "./DetailsTable";

export default function SalaryTable() {
  const [data, setData] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedEnglishMonth, setSelectedEnglishMonth] = useState("");
  const [selectedEnglishYear, setSelectedEnglishYear] = useState("");
  const [storedValue, setStoredValue] = useState("");
  const [storedYear, setStoredYear] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState("Monthly");
  const options = ["Monthly", "Yearly"];
  const params = new URLSearchParams(window.location.search);
  const paramToken = params.get("token");
  const paramReport = params.get("report");
  // const paramType = params.get("type");
  const paramType = "nepali"
  const [selectedId, setSelectedId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  if (paramToken) {
    localStorage.setItem("token", paramToken);
  }

  if (paramReport) {
    localStorage.setItem("report", paramReport);
  }

  const handleMonthChange = (event) => {
    console.log(event.target.value);
    setSelectedMonth(event.target.value);
  };
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleEnglishMonthChange = (event) => {
    setSelectedEnglishMonth(event);
  };
  const handleEnglishYearChange = (event) => {
    setSelectedEnglishYear(event.target.value);
  };

  //for nepali date
  useEffect(() => {
    if (selectedMonth && selectedYear) {
      const filteredItems = MONTH.filter((item) => {
        return item.year === selectedYear && item.label === selectedMonth;
      });
      setStoredValue(filteredItems);
    }
    if (selectedYear) {
      const filteredItems = MONTH.filter((item) => {
        return item.year === selectedYear;
      });
      const firstItem = filteredItems[0];
      const lastItem = filteredItems[filteredItems.length - 1];
      setStoredYear({ firstItem, lastItem });
    }
  }, [selectedYear, selectedMonth]);

  //for english date
  useEffect(() => {
    if (selectedEnglishMonth && selectedEnglishYear) {
      const filteredItems = filterMonth.filter((item) => {
        return (
          item.year === selectedEnglishYear &&
          item.label === selectedEnglishMonth
        );
      });
      setStoredValue(filteredItems);
    }
    if (selectedEnglishYear) {
      const filteredItems = filterMonth.filter((item) => {
        return item.year === selectedYear;
      });

      setStoredYear(filteredItems);
    }
  }, [selectedEnglishMonth, selectedEnglishYear]);

  //START DATE

  //fetch api

  //FOR NEPALI MONTH
  const fetchData = async () => {
    try {
      if (reportType === "Monthly") {
        setLoading(true);
        // Replace 'YOUR_TOKEN' with your actual authorization token
        const acessToken = `${refreshToken}`;

        const response = await axios.get(
          `${baseUrl}/api/salarysheet/?start_date=${storedValue[0]?.start}&end_date=${storedValue[0]?.end}&report=${paramReport}} `,
          {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${acessToken}`,
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("rr", response);
        setData(response?.data?.data);
      } else if (reportType === "Yearly") {
        setLoading(true);
        // Replace 'YOUR_TOKEN' with your actual authorization token
        const acessToken = `${refreshToken}`;

        const response = await axios.get(
          `${baseUrl}/api/salarysheet/?start_date=${storedYear?.firstItem?.start}&end_date=${storedYear?.lastItem?.end} &report=${paramReport}`,
          {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${acessToken}`,
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("rr", response);
        setData(response?.data?.data);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  //FOR ENGLISH MONTH

  const fetchEnglishData = async () => {
    try {
      if (reportType === "Monthly") {
        setLoading(true);
        // Replace 'YOUR_TOKEN' with your actual authorization token
        const acessToken = `${refreshToken}`;

        const response = await axios.get(
          `${baseUrl}/api/salarysheet/?year=${selectedEnglishYear}&month=${selectedEnglishMonth}&report=${paramReport} `,
          {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${acessToken}`,
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setData(response?.data?.data);
      } else if (reportType === "Yearly") {
        setLoading(true);
        const acessToken = `${refreshToken}`;

        const response = await axios.get(
          `${baseUrl}/api/salarysheet/?year=${selectedEnglishYear}&report=${paramReport}`,
          {
            headers: {
              "Content-Type": "application/json",
              // Authorization: `Bearer ${acessToken}`,
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setData(response?.data?.data);
      }
    } catch (error) {
      setLoading(false);

      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const updatedData =
    data &&
    data?.map((item) => {
      const totalAllowance = item?.allowance.reduce(
        (acc, allowance) => acc + allowance.amount,
        0
      );
      const totalAllowanceYearly = item?.allowance.reduce(
        (acc, allowance) => acc + allowance.amount * 12,
        0
      );
      const totalDeduction = item?.deduction.reduce(
        (acc, deduction) => acc + deduction.amount,
        0
      );
      const totalDeductionYearly = item?.deduction.reduce(
        (acc, deduction) => acc + deduction.amount * 12,
        0
      );
      const gratuitySSF = item?.deduction?.find(
        (item) => item?.allowance_deduction_name === "Gratuity+SSF(11%)"
      );
      const providentFund = item?.deduction?.find(
        (item) =>
          item.allowance_deduction_name ===
          "Provident Fund(10% of Basic Salary)"
      );
      const totalGratuitySSF = gratuitySSF ? gratuitySSF.amount : 0;
      const totalProvidentFund = providentFund ? providentFund.amount : 0;
      const taxableAmount = item.salary + totalAllowance;
      const taxableAmountYearly = item.salary * 12 + totalAllowanceYearly;

      const calculatedTaxableIncome =
        taxableAmount -
        totalGratuitySSF -
        totalProvidentFund -
        totalDeduction +
        item?.bonus;

      const calculatedTaxableIncomeYearly =
        taxableAmountYearly -
        totalGratuitySSF * 12 -
        totalProvidentFund * 12 -
        totalDeduction * 12 +
        item?.bonus;

      const marriedbrackets = [
        { rate: 0.01, min: 0, max: 600000 },
        { rate: 0.1, min: 600001, max: 800000 },
        { rate: 0.2, min: 800001, max: 1100000 },
        { rate: 0.3, min: 1100001, max: 2000000 },
        { rate: 0.36, min: 2000001, max: 5000000 },
        { rate: 0.39, min: 5000001, max: Infinity },
      ];
      const brackets = [
        { rate: 0.01, min: 0, max: 500000 },
        { rate: 0.1, min: 500001, max: 700000 },
        { rate: 0.2, min: 700001, max: 1000000 },
        { rate: 0.3, min: 1000001, max: 2000000 },
        { rate: 0.36, min: 2000001, max: 5000000 },
        { rate: 0.39, min: 5000001, max: Infinity },
      ];

      const taxAmountsMarried = marriedbrackets.map(({ rate, min, max }) => ({
        rate: rate * 100, // Convert rate to percentage
        amount: parseInt(
          Math.min(
            Math.max(0, calculatedTaxableIncome - min + 1),
            max - min + 1
          ) * rate
        ),
      }));
      const taxAmountsMarriedYearly = marriedbrackets.map(
        ({ rate, min, max }) => ({
          rate: rate * 100, // Convert rate to percentage
          amount: parseInt(
            Math.min(
              Math.max(0, calculatedTaxableIncomeYearly - min + 1),
              max - min + 1
            ) * rate
          ),
        })
      );
      const taxAmounts = brackets.map(({ rate, min, max }) => ({
        rate: rate * 100, // Convert rate to percentage
        amount: parseInt(
          Math.min(
            Math.max(0, calculatedTaxableIncome - min + 1),
            max - min + 1
          ) * rate
        ),
      }));
      const taxAmountsYearly = brackets.map(({ rate, min, max }) => ({
        rate: rate * 100, // Convert rate to percentage
        amount: parseInt(
          Math.min(
            Math.max(0, calculatedTaxableIncomeYearly - min + 1),
            max - min + 1
          ) * rate
        ),
      }));

      const totalTaxAmountMarried = taxAmountsMarried.reduce(
        (acc, { amount }) => acc + amount,
        0
      );
      const totalTaxAmountMarriedYearly = taxAmountsMarriedYearly.reduce(
        (acc, { amount }) => acc + amount,
        0
      );
      const totalTaxAmount = taxAmounts.reduce(
        (acc, { amount }) => acc + amount,
        0
      );
      const totalTaxAmountYearly = taxAmountsYearly.reduce(
        (acc, { amount }) => acc + amount,
        0
      );
      const netAmount = taxableAmount - totalDeduction - (totalTaxAmount?parseFloat(totalTaxAmountYearly/12).toFixed(2):0);
      const netAmountYearly =
        taxableAmountYearly - totalDeductionYearly - totalTaxAmountYearly;

      const netAmountMarried =
        taxableAmount - totalDeduction - (totalTaxAmountMarriedYearly?parseFloat(totalTaxAmountMarriedYearly/12).toFixed(2):0);
      const netAmountMarriedYearly =
        taxableAmountYearly -
        totalDeductionYearly -
        totalTaxAmountMarriedYearly;
      return {
        ...item,
        total_allowance: totalAllowance,
        total_allowance_yearly: totalAllowanceYearly,
        total_taxable_amount: taxableAmount,
        total_taxable_amount_yearly: taxableAmountYearly,
        total_deduction: totalDeduction,
        gratuitySSF: totalGratuitySSF,
        providentFund: totalProvidentFund,
        total_tax_married: totalTaxAmountMarried,
        total_tax_married_yearly: totalTaxAmountMarriedYearly,

        total_tax: totalTaxAmount,
        total_tax_yearly: totalTaxAmountYearly,
        netAmount: netAmount,
        netAmountYearly: netAmountYearly,
        netAmountMarried: netAmountMarried,
        netAmountMarriedYearly: netAmountMarriedYearly,
      };
    });
  return (
    <Box>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="full">
        <ModalOverlay />
        <ModalContent>
          {/* <ModalHeader>Modal Title</ModalHeader> */}
          <ModalCloseButton />
          <ModalBody>
            {/* Modal content goes here */}
            <DetailsTable
              id={selectedId}
              selectedMonth={selectedMonth}
              storedYear={storedYear}
              storedValue={storedValue}
              selectedEnglishYear={selectedEnglishYear}
              selectedEnglishMonth={selectedEnglishMonth}
              reportType={reportType}
              paramType={paramType}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box className="box-flex" borderBottom={"1px solid lightgray"}>
        <Box className="detail-box">
          <h5 style={{textAlign:'left'}}>Employee payroll tax details</h5>
          <Text style={{textAlign:'left'}}>Employee payroll tax details with pay tax</Text>
        </Box>
        {paramType === "nepali" ? (
          <Box className="date-wrapper">
            <Box display={"flex"} alignItems={"baseline"}>
              <Text id="small-text" w={"7rem"}>
                Month :{" "}
              </Text>
              <Box>
                <Select
                  placeholder="Select a month"
                  onChange={handleMonthChange}
                >
                  {constantMonth?.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box display={"flex"} alignItems={"baseline"}>
              <Text id="small-text" w={"7rem"}>
                Year :{" "}
              </Text>
              <Box>
                <Select placeholder="Select year" onChange={handleYearChange}>
                  {constantYear?.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box ml="1rem">
              <RadioGroup
                defaultValue={options[0]}
                onChange={(value) => setReportType(value)}
              >
                <Stack direction="row">
                  {options.map((option) => (
                    <Radio key={option} value={option} colorScheme="teal">
                      {option}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </Box>

            <Button onClick={() => fetchData()} id="submit-btn">
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Box>
        ) : (
          <Box className="date-wrapper">
            <Box display={"flex"} alignItems={"baseline"}>
              <Text id="small-text" w={"7rem"}>
                Month :{" "}
              </Text>
              <Box>
                <Select
                  placeholder="Select a month"
                  onChange={(e) => handleEnglishMonthChange(e.target.value)}
                >
                  {englishMonth?.map((item, index) => (
                    <option key={index} value={item?.value}>
                      {item.label}
                    </option>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box display={"flex"} alignItems={"baseline"}>
              <Text id="small-text" w={"7rem"}>
                Year :{" "}
              </Text>
              <Box>
                <Select
                  placeholder="Select year"
                  onChange={handleEnglishYearChange}
                >
                  {constantEnglishYear?.map((item, index) => (
                    <option key={index}>{item}</option>
                  ))}
                </Select>
              </Box>
            </Box>
            <Box ml="1rem">
              <RadioGroup
                defaultValue={options[0]}
                onChange={(value) => setReportType(value)}
              >
                <Stack direction="row">
                  {options.map((option) => (
                    <Radio key={option} value={option} colorScheme="teal">
                      {option}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            </Box>
            {paramType === "nepali" ? (
              <Button onClick={() => fetchData()} id="submit-btn">
                {loading ? "Loading..." : "Submit"}
              </Button>
            ) : (
              <Button onClick={() => fetchEnglishData()} id="submit-btn">
                {loading ? "Loading..." : "Submit"}
              </Button>
            )}
          </Box>
        )}
      </Box>

      <TableContainer>
        <Table variant="striped">
          <Thead>
            <Tr>
              <Th>Employee Id</Th>
              <Th>Employee Name</Th>
              <Th>Basic Salary{reportType==="Monthly"?"/M":"/Y"}</Th>
              <Th>Total Allowance</Th>
              <Th>Total Deduction</Th>
              <Th>Taxable Amount</Th>
              <Th>Tax Amount</Th>
              <Th>Net Amount</Th>
            </Tr>
          </Thead>
          {updatedData?.length > 0 ? (
            <>
              <Tbody>
                {updatedData &&
                  updatedData?.map((item, index) => {
                    return (
                      <>
                        <Tr
                          // onClick={() =>
                          //   navigate(
                          //     `/details/${
                          //       item?.uuid
                          //     }?token=${localStorage.getItem("token")}`,
                          //     {
                          //       state: {
                          //         selectedMonth,
                          //         storedYear,
                          //         storedValue,
                          //         selectedEnglishYear,
                          //         selectedEnglishMonth,
                          //         reportType,
                          //         paramType,
                          //       },
                          //     } // Pass selectedMonth in the state
                          //   )
                          // }
                          onClick={() => {
                            setIsOpen(true);

                            setSelectedId(item?.uuid);
                          }}
                        >
                          <Td>{item?.employee_id ?? "-"}</Td>
                          <Td>
                            {item?.first_name} {item?.last_name}
                          </Td>
                          {reportType === "Monthly" ? (
                            <Td>{item?.salary}</Td>
                          ) : (
                            <Td>{item?.salary * 12}</Td>
                          )}
                          {reportType === "Monthly" ? (
                            <Td>{item?.total_allowance}</Td>
                          ) : (
                            <Td>{item?.total_allowance_yearly}</Td>
                          )}
                          <Td>{reportType === "Monthly"?item?.total_deduction:item?.total_deduction*12}</Td>
                          {reportType === "Monthly" ? (
                            <Td>
                              {item?.total_taxable_amount -
                                item?.gratuitySSF -
                                item?.providentFund -
                                item?.total_deduction}
                            </Td>
                          ) : (
                            <Td>
                              {item?.total_taxable_amount_yearly -
                                item?.gratuitySSF * 12 -
                                item?.providentFund * 12 -
                                item?.total_deduction * 12}
                            </Td>
                          )}

                          {item?.marital_status ? (
                            reportType === "Monthly" ? (
                              <Td>{item?.total_tax_married_yearly?(parseFloat(item?.total_tax_married_yearly/12).toFixed(2)):0}</Td>
                            ) : (
                              <Td>{item?.total_tax_married_yearly}</Td>
                            )
                          ) : reportType === "Monthly" ? (
                            <Td>{item?.total_tax_yearly?(parseFloat(item?.total_tax_yearly/12).toFixed(2)):0}</Td>
                          ) : (
                            <Td>{item?.total_tax_yearly}</Td>
                          )}
                          

                          {item?.marital_status ? (
                            reportType === "Monthly" ? (
                              <Td>NPR.{item?.netAmountMarried}</Td>
                            ) : (
                              <Td>NPR.{item?.netAmountMarriedYearly}</Td>
                            )
                          ) : reportType === "Monthly" ? (
                            <Td>NPR.{item?.netAmount}</Td>
                          ) : (
                            <Td>NPR.{item?.netAmountYearly}</Td>
                          )}
                        </Tr>
                      </>
                    );
                  })}
              </Tbody>
            </>
          ) : (
            <Tr>
              <Td></Td>
              <Td>No data found</Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
              <Td></Td>
            </Tr>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
