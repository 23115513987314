import logo from "./logo.svg";
import "./App.css";
import SalaryTable from "./pages/SalaryTable";
import { useEffect } from "react";

function App() {
  return (
    <div>
      <SalaryTable />
    </div>
  );
}

export default App;
