import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import MOnthlyDeduction from "../views/MonthlyDeduction";
import TaxableIncome from "../views/TaxableINcome";
import TaxBracket from "../views/TaxBracket";
import { Box, Button, Text } from "@chakra-ui/react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { refreshToken, baseUrl } from "../constants/constant";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
export default function DetailsTable({
  selectedMonth,
  storedYear,
  storedValue,
  selectedEnglishYear,
  selectedEnglishMonth,
  reportType,
  paramType,
  id,
}) {
  const [salaryDetails, setSalaryDetails] = useState("");
  const [monthlyGrossTotal, setMonthlyGrossTotal] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [selectedDeductions, setSelectedDeductions] = useState([]);
  const [leaveDetails, setLeaveDetails] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [editOvertime, setEditOvertime] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  console.log("ssu", selectedEnglishYear);

  useEffect(() => {
    if (id && reportType === "Monthly" && paramType === "nepali") {
      const accessToken = `${refreshToken}`;

      axios
        .get(
          `${baseUrl}/api/usersalarysheet/?uuid=${id ?? ""}&start_date=${
            storedValue[0]?.start
          }&end_date=${storedValue[0]?.end}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              // Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          setSalaryDetails(response?.data?.data);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error fetching data:", error);
        });
    } else if (id && reportType === "Yearly" && paramType === "nepali") {
      const accessToken = `${refreshToken}`;

      axios
        .get(
          `${baseUrl}/api/usersalarysheet/?uuid=${id ?? ""}&start_date=${
            storedYear?.firstItem?.start
          }&end_date=${storedYear?.lastItem?.end}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              // Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          setSalaryDetails(response?.data?.data);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error fetching data:", error);
        });
    }
  }, [id, token]);

  useEffect(() => {
    if (id && reportType === "Monthly") {
      const accessToken = `${refreshToken}`;

      axios
        .get(
          `${baseUrl}/api/usersalarysheet/?uuid=${
            id ?? ""
          }&year=${selectedEnglishYear}&month=${selectedEnglishMonth}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              // Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          setSalaryDetails(response?.data?.data);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error fetching data:", error);
        });
    } else if (id && reportType === "Yearly") {
      const accessToken = `${refreshToken}`;

      axios
        .get(
          `${baseUrl}/api/usersalarysheet/?uuid=${
            id ?? ""
          }&year=${selectedEnglishYear}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              // Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          setSalaryDetails(response?.data?.data);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error fetching data:", error);
        });
    }
  }, [id, token]);

  useEffect(() => {
    if (id && reportType === "Monthly" && paramType === "nepali") {
      const accessToken = `${refreshToken}`;

      axios
        .get(
          `${baseUrl}/api/v1/attendance/all/?uuid=${id ?? ""}&start_date=${
            storedValue[0]?.start
          }&end_date=${storedValue[0]?.end}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              // Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          setLeaveDetails(response?.data);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error fetching data:", error);
        });
    } else if (id && reportType === "Yearly" && paramType === "nepali") {
      const accessToken = `${refreshToken}`;

      axios
        .get(
          `${baseUrl}/api/v1/attendance/all/?uuid=${id ?? ""}&start_date=${
            storedYear?.firstItem?.start
          }&end_date=${storedYear?.lastItem?.end}`,
          {
            headers: {
              // Authorization: `Bearer ${token}`,
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          setLeaveDetails(response?.data);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error fetching data:", error);
        });
    }
  }, [id, token]);

  useEffect(() => {
    if (id && reportType === "Monthly") {
      const accessToken = `${refreshToken}`;

      axios
        .get(
          `${baseUrl}/api/v1/attendance/all/?uuid=${
            id ?? ""
          }&year=${selectedEnglishYear}&month=${selectedEnglishMonth}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              // Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          setLeaveDetails(response?.data);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error fetching data:", error);
        });
    } else if (id && reportType === "Yearly") {
      const accessToken = `${refreshToken}`;

      axios
        .get(
          `${baseUrl}/api/v1/attendance/all/?uuid=${
            id ?? ""
          }&year=${selectedEnglishYear}`,
          {
            headers: {
              // Authorization: `Bearer ${token}`,
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          setLeaveDetails(response?.data);
        })
        .catch((error) => {
          // Handle any errors that occur during the request
          console.error("Error fetching data:", error);
        });
    }
  }, [id, token]);

  //CALCULATING MONTHLY TOTAL
  useEffect(() => {
    const calculateMonthlyGrossTotal = () => {
      const basicSalary = salaryDetails?.salary || 0;

      const allowanceTotal =
        salaryDetails?.allowance?.reduce(
          (total, item) => total + (item?.amount || 0),
          0
        ) || 0;

      const total = basicSalary + allowanceTotal;

      setMonthlyGrossTotal(total);
    };

    calculateMonthlyGrossTotal();
  }, [salaryDetails]);

  //CALCULATING MONTHLY DEDUCTION

  useEffect(() => {
    const calculateTotalDeduction = () => {
      const deductionTotal =
        salaryDetails?.deduction?.reduce(
          (total, item) => total + (item?.amount || 0),
          0
        ) || 0;

      setTotalDeduction(deductionTotal);
    };

    calculateTotalDeduction();
  }, [salaryDetails]);

  // Storing granulity and PF in state for calcuation
  useEffect(() => {
    const selectedDeductionNames = [
      "Gratuity+SSF(11%)",
      "Provident Fund(10% of Basic Salary)",
    ];

    const filteredDeductions = salaryDetails?.deduction?.filter((item) =>
      selectedDeductionNames.includes(item?.allowance_deduction_name)
    );

    setSelectedDeductions(filteredDeductions);
  }, [salaryDetails]);

  return (
    <>
      <Box display={"flex"} justifyContent={"center"} mb={"1rem"}>
        {/* <Button
          className="arrow-btn"
          onClick={() => navigate("/")}
          display={"flex"}
        >
          <FontAwesomeIcon icon={faArrowLeft} style={{ marginRight: "8px" }} />
          Back To List
        </Button> */}
      </Box>
      <Box className="main-leave-container">
        <Box className="leave-container" display={'flex'} flexWrap={'wrap'}>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Text className="present" fontWeight={"bold"}>
              {salaryDetails?.present}
            </Text>

            <Text fontWeight={"bold"}> Present</Text>
          </Box>{" "}
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Text className="late" fontWeight={"bold"}>
              {salaryDetails?.late}
            </Text>

            <Text fontWeight={"bold"}> Late</Text>
          </Box>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Text className="absent" fontWeight={"bold"}>
              {salaryDetails?.absent}
            </Text>

            <Text fontWeight={"bold"}>Absent</Text>
          </Box>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Text className="absent" fontWeight={"bold"}>
              {salaryDetails?.leave}
            </Text>

            <Text fontWeight={"bold"}>Leave</Text>
          </Box>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Text className="absent" fontWeight={"bold"}>
              {salaryDetails?.holiday_this_month}
            </Text>

            <Text fontWeight={"bold"}>Holiday This Month</Text>
          </Box>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Text className="absent" fontWeight={"bold"}>
              {salaryDetails?.over_time}
            </Text>

            <Text fontWeight={"bold"}>Overtime Hours</Text>
          </Box>
        </Box>
        <Text><i>Note: Deduction hasn't been made for leave/absent recorded. Overtime Hour amount hasn't been compensated. !!!important: New updates coming soon for payroll report!!!</i></Text>
        {/* <Box textAlign={"left"}>
          <Text fontWeight={"bold"}>Overtime: {salaryDetails?.over_time}</Text>
          <Text fontWeight={"bold"}>
            Absent Deduction: {salaryDetails?.total_absent}
          </Text>
        </Box> */}
      </Box>

      <Box className="box-container" mt="2rem">
        {reportType === "Monthly" ? (
          <Table striped bordered id="box-width">
            <thead className="thead-colored">
              <tr>
                <th style={{ background: "#86b7fe", color: "#fff" }}></th>
                <th style={{ background: "#86b7fe", color: "#fff" }}>
                  Monthly
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Basic Salary</td>
                <td>{salaryDetails?.salary}</td>
              </tr>
              {salaryDetails?.allowance?.map((item, index) => {
                return (
                  <>
                    <tr>
                      <td>{item?.allowance_deduction_name}</td>
                      <td>{item?.amount}</td>
                    </tr>
                  </>
                );
              })}

              <tr>
                <td>Montly Gross Total</td>
                <td>{monthlyGrossTotal}</td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <Table striped bordered id="box-width">
            <thead className="thead-colored">
              <tr>
                <th style={{ background: "#86b7fe", color: "#fff" }}></th>
                <th style={{ background: "#86b7fe", color: "#fff" }}>
                  Monthly
                </th>
                <th style={{ background: "#86b7fe", color: "#fff" }}>
                  Annually
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Basic Salary</td>
                <td>{salaryDetails?.salary}</td>
                <td>{salaryDetails?.salary * 12}</td>
              </tr>
              {salaryDetails?.allowance?.map((item, index) => {
                return (
                  <>
                    <tr>
                      <td>{item?.allowance_deduction_name}</td>
                      <td>{item?.amount}</td>
                      <td>{item?.amount * 12}</td>
                    </tr>
                  </>
                );
              })}

              <tr>
                <td>Montly Gross Total</td>
                <td>{monthlyGrossTotal}</td>
                <td>{monthlyGrossTotal * 12}</td>
              </tr>
            </tbody>
          </Table>
        )}

        <MOnthlyDeduction
          selectedMonth={selectedMonth}
          salaryDetails={salaryDetails}
          totalDeduction={totalDeduction}
          reportType={reportType}
        />

        <TaxableIncome
          selectedMonth={selectedMonth}
          monthlyGrossTotal={monthlyGrossTotal}
          totalDeduction={totalDeduction}
          selectedDeductions={selectedDeductions}
          salaryDetails={salaryDetails}
          reportType={reportType}
        />
      </Box>
    </>
  );
}
