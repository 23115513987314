import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import "bootstrap/dist/css/bootstrap.min.css";
import { Box } from "@chakra-ui/react";
export default function TaxBracket({
  calculatedTaxableIncome,
  monthlyGrossTotal,
  totalDeduction,
  salaryDetails,
  selectedMonth,
  reportType,
}) {
  const [tax, setTax] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [totalTaxMarried, setTotalTaxMarried] = useState(0);

  const marriedbrackets = [
    { rate: 0.01, min: 0, max: 600000 },
    { rate: 0.1, min: 600001, max: 800000 },
    { rate: 0.2, min: 800001, max: 1100000 },
    { rate: 0.3, min: 1100001, max: 2000000 },
    { rate: 0.36, min: 2000001, max: 5000000 },
    { rate: 0.39, min: 5000001, max: Infinity },
  ];
  // Calculate tax amounts for married
  const taxAmountsMarried = marriedbrackets.map(({ rate, min, max }) => ({
    rate: rate * 100, // Convert rate to percentage
    amount: parseInt(
      Math.min(Math.max(0, calculatedTaxableIncome - min + 1), max - min + 1) *
        rate
    ),
  }));
  const brackets = [
    { rate: 0.01, min: 0, max: 500000 },
    { rate: 0.1, min: 500001, max: 700000 },
    { rate: 0.2, min: 700001, max: 1000000 },
    { rate: 0.3, min: 1000001, max: 2000000 },
    { rate: 0.36, min: 2000001, max: 5000000 },
    { rate: 0.39, min: 5000001, max: Infinity },
  ];
  console.log("ii", calculatedTaxableIncome);
  // Calculate tax amounts
  const taxAmounts = brackets.map(({ rate, min, max }) => ({
    rate: rate * 100, // Convert rate to percentage
    amount: parseInt(
      Math.min(Math.max(0, calculatedTaxableIncome - min + 1), max - min + 1) *
        rate
    ),
  }));

  // Calculate total tax
  useEffect(() => {
    const total = taxAmounts.reduce((sum, { amount }) => sum + amount, 0);
    setTotalTax(total);
  }, [calculatedTaxableIncome]);

  // Calculate total tax married
  useEffect(() => {
    const total = taxAmountsMarried.reduce(
      (sum, { amount }) => sum + amount,
      0
    );
    setTotalTaxMarried(total);
  }, [calculatedTaxableIncome]);
  console.log("tax", taxAmounts);
  return (
    <Box mt="2rem">
      <Table striped bordered hover id="box-width">
        <thead>
          <tr>
            <th style={{ background: "#7c7c18", color: "#fff" }}>
              Tax Bracket
            </th>
            <th style={{ background: "#7c7c18", color: "#fff" }}>Tax Amount</th>
          </tr>
        </thead>
        <tbody>
          {salaryDetails?.marital_status ? (
            <>
              {taxAmountsMarried.map(({ rate, amount }, index) => (
                <tr key={index}>
                  <td>{`${rate}%`}</td>
                  <td>{`${amount}`}</td>
                </tr>
              ))}
              {
                reportType === "Monthly" &&
                <tr>
                  <td><b>Total Tax Deduction/Month</b></td>
                  <td><b>{totalTaxMarried?(parseFloat(totalTaxMarried/12).toFixed(2)):0}</b></td>
                </tr>
              }
              <tr>
                <td>Total Tax Deduction/Year</td>
                <td>{totalTaxMarried}</td>
              </tr>
            </>
          ) : (
            <>
              {taxAmounts.map(({ rate, amount }, index) => (
                <tr key={index}>
                  <td>{`${rate}%`}</td>
                  <td>{`${amount}`}</td>
                </tr>
              ))}
                {
                  reportType === "Monthly" &&
                    <tr>
                      <td><b>Total Tax Deduction/Month</b></td>
                      <td><b>{totalTax?(parseFloat(totalTax/12).toFixed(2)):0}</b></td>
                    </tr>
                }
              <tr>
                <td>Total Tax Deduction/Year</td>
                <td>{totalTax}</td>
              </tr>
            </>
          )}
        </tbody>
      </Table>
      {/* <Box mt="1rem">
        {reportType === "Monthly" ? (
          <Table striped bordered hover id="box-width">
            <thead>
              <tr>
                <th style={{ background: "green", color: "#fff" }}>
                  Total Amount
                </th>
                <th style={{ background: "green", color: "#fff" }}>
                  {monthlyGrossTotal}
                </th>
              </tr>
            </thead>
          </Table>
        ) : (
          <Table striped bordered hover id="box-width">
            <thead>
              <tr>
                <th style={{ background: "green", color: "#fff" }}>
                  Total Amount
                </th>
                <th style={{ background: "green", color: "#fff" }}>
                  {monthlyGrossTotal}
                </th>
                <th style={{ background: "green", color: "#fff" }}>
                  {monthlyGrossTotal * 12}
                </th>
              </tr>
            </thead>
          </Table>
        )}
      </Box> */}
      <Box mt="1rem">
        {reportType === "Monthly" ? (
          <Table striped bordered hover id="box-width">
            <thead>
              <tr>
                <th style={{ background: "#d9b648", color: "#fff" }}>
                  Take home after CIT , PF & Taxes
                </th>
                {salaryDetails?.marital_status ? (
                  <th style={{ background: "#d9b648", color: "#fff" }}>
                    {
                      monthlyGrossTotal - totalDeduction - (reportType==="Monthly"?parseFloat(totalTaxMarried?(totalTaxMarried/12):0.0).toFixed(2):totalTaxMarried)
                    }
                  </th>
                ) : (
                  <th style={{ background: "#d9b648", color: "#fff" }}>
                    { 
                      monthlyGrossTotal - totalDeduction - (reportType==="Monthly"?parseFloat(totalTax?(totalTax/12):0.0).toFixed(2):totalTax)
                    }
                  </th>
                )}
              </tr>
            </thead>
          </Table>
        ) : (
          <Table striped bordered hover id="box-width">
            <thead>
              <tr>
                <th style={{ background: "#d9b648", color: "#fff" }}>
                  Take home after CIT , PF & Taxes{" "}
                </th>
                {salaryDetails?.marital_status ? (
                  <th style={{ background: "#d9b648", color: "#fff" }}>
                    {monthlyGrossTotal * 12 -
                      totalDeduction * 12 -
                      totalTaxMarried}
                  </th>
                ) : (
                  <th style={{ background: "#d9b648", color: "#fff" }}>
                    {monthlyGrossTotal * 12 - totalDeduction * 12 - totalTax}
                  </th>
                )}
              </tr>
            </thead>
          </Table>
        )}
      </Box>
    </Box>
  );
}
